import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

interface ImagesProps {
  image?: ImageProps
}

export interface Props {
  images: ImagesProps[]
  instagramURL?: string
  pretitle?: string
  subtitle?: string
  title?: string
}

export const InstagramSlider = memo(function InstagramSlider({
  images,
  instagramURL,
  pretitle,
  subtitle,
  title,
}: Props) {
  if (!images) {
    return null
  }
  if (images?.length < 1) {
    return null
  }

  return (
    <Container>
      <LazyLoadComponent threshold={100}>
        <Background>
          <ParallaxProvider>
            <Parallax className="slider-parallax" translateY={['-15%', '15%']}>
              <BackgroundImage src="/instagram_bg.webp" alt="Instagram" />
            </Parallax>
          </ParallaxProvider>
        </Background>
      </LazyLoadComponent>

      <Wrapper>
        <Heading>
          {pretitle ? (
            <FadeInUp>
              <Line />
              <Pretitle>{pretitle}</Pretitle>
            </FadeInUp>
          ) : null}
          {title ? <Title text={title} /> : null}
          {subtitle ? (
            <FadeInUp>
              <Subtitle href={instagramURL} target="_blank">
                {subtitle}
              </Subtitle>
            </FadeInUp>
          ) : null}
          <FadeInUp>
            <Icon src="/hash.svg" alt="hash" />
          </FadeInUp>
        </Heading>
        <Images href={instagramURL} target="_blank">
          {images.map((item, index) => (
            <FadeInUp delay={index * 0.15} key={index}>
              <LazyLoadComponent key={index}>
                <StyledImage key={index} {...item.image} />
              </LazyLoadComponent>
            </FadeInUp>
          ))}
        </Images>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  background-color: ${({ theme }) => theme.colors.variants.neutralDark2};
  margin-bottom: -1px;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 26.38vw;
  width: 100%;
  max-height: -webkit-fill-available;
  margin-top: 12.5vw;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  overflow: hidden;
  z-index: 0;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    opacity: 0.77;
    backface-visibility: hidden;
    z-index: 3;
  }

  @media (max-width: 991px) {
    margin-top: 55px;
    height: 35vw;
  }
`

const BackgroundImage = styled.img`
  width: 100%;
`

const Wrapper = styled.div`
  padding: 5vw 10vw 0;

  @media (max-width: 991px) {
    padding: 30px;
    padding-top: 0;
  }
`

const Heading = styled.div`
  position: relative;
  text-align: center;
  z-index: 1;
`

const Pretitle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.83vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.69vw;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @media (max-width: 1439px) {
    font-size: 10px;
    margin-left: 12px;
  }
`

const Title = styled(AnimatedTitle)`
  margin-top: 3.33vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 4.16vw;
  line-height: 1.15;

  @media (max-width: 991px) {
    font-size: 28px;
    margin-top: 20px;
  }
`

const Subtitle = styled.a`
  display: block;
  max-width: max-content;
  margin: 1.31vw auto 0;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 1.38vw;

  @media (max-width: 991px) {
    font-size: 15px;
    margin-top: 20px;
  }
`

const Icon = styled.img`
  height: 2.5vw;
  width: auto;
  margin-top: 3.33vw;

  @media (max-width: 991px) {
    height: 20px;
  }
`

const Images = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5.2vw;
  margin-left: -1.8vw;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  width: 14.18vw;
  height: 14.18vw;
  aspect-ratio: 1;
  border-radius: 0.69vw;
  margin-left: 1.8vw;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.69vw;
  }
`
