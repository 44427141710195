import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  className?: string
  onClick?: (e: any) => void
}

export const Dot = memo(function Dot({ className, onClick }: Props) {
  return <Container className={className} onClick={onClick} />
})

const Container = styled.div`
  position: relative;
  width: 0.416vw;
  height: 0.416vw;
  margin: 1.66vw 0;
  background: ${rgba(theme.colors.variants.neutralLight1, 0.3)};
  border-radius: 50%;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  @media (max-width: 1439px) {
    width: 0.375rem;
    height: 0.375rem;
    margin: 1.5rem 0;
  }

  &.active {
    background: ${rgba(theme.colors.variants.neutralLight1, 1)};
    pointer-events: none;
  }
`
